<template>
  <div>
    <h1>
      DATASOURCES
    </h1>
    <cbs-object :objectid="11"
                @close="closeCard"
    />
  </div>
</template>

<script>
import CbsObject from '@/cubus/components/object/CbsObject.vue'

export default {
  components: {
    CbsObject,
  },
  methods: {
    closeCard() {},
  },
}
</script>

<style scoped>

</style>
